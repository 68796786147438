// import router from './router/index'
// import store from './store'
// import {checkForBrowser} from './utils/common'
// import {getCookies, removeCookies} from '@/utils/cookies'
// import MyWorkApp from '@/api/MyWorkApp.js'

// router.beforeEach(async (to, from, next) => {
//   console.log('clic k click ')
//   console.log('clic k click ', to)
//   console.log('clic k click ', from)
//   console.log('clic k click ', next)
//   // store.dispatch('SetLoader', {status: true, message: 'Loading...'})
//   // let authToken = localStorage.getItem('authToken')
//   let authToken = getCookies('authToken')
//   // console.log('from', from.query)
//   // console.log('authToken', to)
//   let companyData = localStorage.getItem('companyData')
//   checkForBrowser()
//   console.log('navigator.onLine', navigator.onLine)
//   if (((to.name === 'login' || to.name === 'verify') && authToken === null) || to.name === 'VerifyOtpComponent' || to.name === 'SelectCompanyForSetPassword' || to.name === 'EnterEmailComponent' || (to.name === 'companyList' && companyData !== null) || to.name === 'SignUp' || to.name === 'redirect' || to.name === 'AutoLogin' || to.name === 'ipRequest') {
//     next()
//   } else {
//     if (authToken === null) {
//       localStorage.setItem('routeNextToA', to.fullPath)
//       next({
//         name: 'login'
//       })
//     } else {
//       let permissionResponseData = ''
//       if (store.getters.permission.permissionList === undefined) {
//         try {
//           permissionResponseData = await store.dispatch('GetEmployeePermission', false)
//         } catch (err) {
//           localStorage.clear()
//           removeCookies('authToken')
//           next({name: 'login'})
//           return
//         }
//       }
//       if (to.params.projectId) {
//         if (store.getters.projectPermission.projectId !== parseInt(to.params.projectId)) {
//           permissionResponseData = await store.dispatch('GetProjectSpecificPermission', parseInt(to.params.projectId))
//         }
//       } else if (store.getters.projectPermission.projectId > 0) {
//         permissionResponseData = await store.dispatch('GetProjectSpecificPermission', 0)
//       }
//       if (permissionResponseData === 'defaultImportScreen') {
//         next({name: 'CompanySetup'})
//       }
//     }
//   }
// })
// router.afterEach((to) => {
//   console.log('topath', to)
//   let authToken = getCookies('authToken')
//   console.log('to.name', to.name)
//   console.log('authToken', authToken)
//   if (authToken !== null) {
//     MyWorkApp.CheckIpExpireOrNot(
//       () => {
//         // console.log('response CheckIpExpireOrNot', response)
//         store.dispatch('SetLoader', {status: false, message: ''})
//         store.dispatch('SetShowIpExpiredPopup', {showIpExpirePopup: false})
//       },
//       error => {
//         if (error.Data !== undefined || error.Data !== null) {
//           if (error.Data === true) {
//             console.log('CheckIpExpireOrNot', error)
//             store.dispatch('SetShowIpExpiredPopup', {showIpExpirePopup: true})
//             setTimeout(() => {
//               removeCookies('authToken')
//               localStorage.clear()
//             }, 500)
//           }
//         }
//       }
//     )
//     setTimeout(() => {
//       let addHistoryObj = {
//         historyId: 0,
//         historyTitle: document.title,
//         historyUrl: to.fullPath
//       }
//       MyWorkApp.AddHistory(
//         addHistoryObj,
//         response => {
//           console.log('response add history', response)
//           store.dispatch('SetLoader', {status: false, message: ''})
//         },
//         error => {
//           console.log(error)
//         }
//       )
//     }, 1500)
//   }
// })


import router from './router/index'
import store from './store'
import { getCookies, removeCookies } from '@/utils/cookies'
router.beforeEach(async(to, from, next) => {
    document.title = 'Login'
    var authToken = getCookies('authToken')
    if (authToken === undefined || authToken === null) {
        if (to.path !== '/login') {
            localStorage.setItem('routeNextToA', to.fullPath)
            next({
                name: 'login'
            })
        } else {
            next()
        }
    } else {
        if (to.path !== '/login') {
            if (store.getters.isAdmin === '' || store.getters.latitude === '') {
                console.log('store.getters.latitude', store.getters.latitude)
                store.dispatch('GetProjectSpecificPermission').then(res => {
                    console.log(res)
                    next()
                }).catch(() => {
                    removeCookies('authToken')
                    localStorage._ruData55 = JSON.stringify(to)
                    next('/')
                })
            } else {
                next()
            }
        } else {
            next({
                name: 'Projects'
            })
        }
    }
})