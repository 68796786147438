<template>
  <div class="text-xs-center">
    <div style="position: fixed;width: 100%;top: 0;right: 0;display: flex;justify-content: center; z-index: 10;">
      <div style="">
        <div class="rounded flex p-5" :class="colorVal">
          <div class="text-white heading-4">
            {{$store.getters.alertMessage}}
          </div>
          <div class="pl-8">
            <button
              dark
              text
              class="font-weight-bold text-white cursor-pointer heading-4"
              @click="$store.dispatch('SetAlert', {showAlert: false, message: '', color: ''})"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['status', 'message', 'color'],
  data () {
    return {
      colorVal: '',
      show: this.$store.getters.showAlert
    }
  },
  mounted () {
    if (this.$store.getters.alertColor === 'success') {
      this.colorVal = 'bg-green-500'
    } else if (this.$store.getters.alertColor === 'error') {
      this.colorVal = 'bg-red-500'
    }
    console.log('this.colorVal', this.colorVal)
    setTimeout(() => {
      this.$store.dispatch('SetAlert', {showAlert: false, message: '', color: ''})
    }, 5000)
  },
  watch: {
    // show: {
    //   handler (value) {
    //     this.$store.dispatch('SetAlert', {showAlert: value, message: '', color: ''})
    //   }
    // }
  }
}
</script>
