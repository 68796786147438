import Vue from 'vue'
import App from './App.vue'
// import './assets/tags'
import './assets/tailwind.css'
import './assets/globals.css'
import './assets/project.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'quill-mention/dist/quill.mention.min.css'
import router from './router'
import store from './store'
import './filter/filter.js'
import './permission.js'
import vClickOutside from 'v-click-outside'
Vue.config.productionTip = false
import VueRx from "vue-rx"; Vue.use(VueRx);
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd'
Vue.use(VuejsClipper)
Vue.use(vClickOutside)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')