
import Api from './API'
import {getCookies} from '@/utils/cookies'
// import { get } from 'http'
// const crypto = require('crypto')

function MyWorkAppModal () {
  if (!(this instanceof MyWorkAppModal)) {
    return new MyWorkAppModal()
  }
}
// ++++++++++++++++++++++++++++ user login and user data api call start ++++++++++++++++++++++++++++
// var keyStr = 'My*W0#kp@pp%!'
// init app api
/*
* staff login api
*/


MyWorkAppModal.prototype.loginToSpecificCompany = async function (
  emailAddress,
  password,
  callBackFunc,
  onError
) {
  let data = new FormData()
  data.append('emailAddress', emailAddress)
  data.append('password', password)
  Api.loginToSpecificCompany(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
// EMPLOYEE LOGIN

MyWorkAppModal.prototype.login = async function (
  loginUser,
  callBackFunc,
  onError
) {
  const data = JSON.stringify(loginUser)
  Api.login(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

// -------------------------------update emp details-----------------------------------------
// upadate basic details

// ALL Shift List
MyWorkAppModal.prototype.OpenShiftList = async function (
  formData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('formData', JSON.stringify(formData))
  Api.OpenShiftList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
MyWorkAppModal.prototype.getUserList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('isActive', isActive)
  Api.getUserList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


// delete document from tempt


MyWorkAppModal.prototype.GetTrackingAccess = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.GetTrackingAccess(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

/** ****************************NOTES*****************************/

MyWorkAppModal.prototype.DeviceRequestPersistent = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  Api.DeviceRequestPersistent(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

MyWorkAppModal.prototype.GetDatabaseUsageOFEmployee = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))

  Api.GetDatabaseUsageOFEmployee(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}




MyWorkAppModal.prototype.getProjectPermission = async function (
  projectId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('authToken'))
  data.append('projectId', projectId)
  Api.getProjectPermission(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}









export default MyWorkAppModal
