<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import './assets/tailwind.css'
export default {
  name: "App",
  components: {},
  mounted() {
  },
};
</script>

<style>
</style>
