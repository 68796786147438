<template>
  <div class="text-xs-center">
    <div style="position: fixed;height: 100%;width: 100%;top: 0;right: 0;z-index: 1000;background: rgba(0,0,0,0.5);display: flex;justify-content: center;align-items: center;">
      <div style="width:300px">
        <div class="bg-primary rounded"
        >
          <div class="p-4 pt-3 text-white text-md">
           <span >{{$store.getters.loaderMessage}}</span>
            <div class="demo-container pt-2">
              <div class="progress-bar">
                <div class="progress-bar-value"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </v-dialog> -->
  </div>
</template>
<script>
export default {
  data () {
    return {
      isLoading: true,
      isFullPage: true,
      // message: this.data,
      dialog: true
    }
  },
  watch: {

  },
  mounted () {
  }
}
</script>

<style scoped>
.v-overlay {
    z-index: 10000!important;
}
.v-dialog__content {
    z-index: 10002!important;
}

.demo-container {
  width: 266px;
  margin: auto;
}

.progress-bar {
  height: 5px;
  background-color: rgba(218, 218, 218, 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
</style>
