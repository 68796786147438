<template>
  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" :width="width" :height="height" fill="black"><path d="M 15 3 L 15 19.9375 L 19 17.90625 L 23 20 L 23 3 Z M 11 6 C 7.636719 6 5 8.636719 5 12 L 5 33 C 5 36.363281 7.636719 39 11 39 L 12 39 L 12 47.21875 L 21.375 39 L 40 39 C 43.363281 39 46 36.363281 46 33 L 46 12 C 46 8.636719 43.363281 6 40 6 L 25 6 L 25 22.375 L 19 30 L 13 22.375 L 13 6 Z M 19 20.09375 L 15.28125 21.96875 L 17.65625 25 L 20.3125 25 L 22.65625 22 Z M 19.125 30 L 44 30 L 44 33 C 44 35.242188 42.242188 37 40 37 L 23.53125 37 Z"/></svg>
  </template>
  <script>
  export default {
    name: 'sheduleSvg', // vue component name
    props: {
      color: {
        type: String,
        default: 'black'
      },
      width: {
        type: String,
        default: '40px'
      },
      height: {
        type: String,
        default: '40px'
      }
    },
    data () {
      return {
      }
    }
  }
  </script>
  