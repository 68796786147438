<template>
<svg :width="width" :height="height" fill="white" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>dashboard</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill-rule="evenodd">
        <g id="component/left-menu-collapsed" transform="translate(-20.000000, -101.000000)" fill-rule="nonzero">
            <g id="Group" transform="translate(15.000000, 96.000000)">
                <g id="dashboard" transform="translate(5.000000, 5.000000)">
                    <path d="M23.2520325,0 C22.804878,0 22.4471545,0.359771055 22.4471545,0.809484873 L22.4471545,16.8438267 C22.4471545,17.2935405 22.804878,17.6533115 23.2520325,17.6533115 L39.195122,17.6533115 C39.6422764,17.6533115 40,17.2935405 40,16.8438267 C40,12.7309894 38.5121951,8.77350777 35.804878,5.69092396 C32.6260163,2.07686018 28.0487805,0 23.2520325,0 Z M24.0569106,16.0343418 L24.0569106,1.64349959 C28.097561,1.86426819 31.902439,3.70400654 34.601626,6.77023712 C36.8699187,9.34587081 38.1869919,12.6165168 38.3739837,16.0343418 L24.0569106,16.0343418 Z" id="Shape"></path>
                    <path d="M36.0731707,21.8560916 C36.0731707,21.4063778 35.7154472,21.0466067 35.2682927,21.0466067 L18.8455285,21.0466067 L18.8455285,4.52166803 C18.8455285,4.07195421 18.4878049,3.71218316 18.0406504,3.71218316 C8.08943089,3.71218316 0,11.847915 0,21.8560916 C0,31.8560916 8.08943089,40 18.0406504,40 C27.9918699,40 36.0731707,31.8560916 36.0731707,21.8560916 Z M18.0406504,38.3810303 C8.98373984,38.3810303 1.6097561,30.9648406 1.6097561,21.8560916 C1.6097561,13.0171709 8.54471545,5.77269011 17.2357724,5.34750613 L17.2357724,21.847915 C17.2357724,22.2976288 17.5934959,22.6573998 18.0406504,22.6573998 L34.4471545,22.6573998 C34.0243902,31.3982011 26.8292683,38.3810303 18.0406504,38.3810303 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
</svg>

</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
