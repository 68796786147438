<template>
  <div>
    <div v-if="!mobileView" id="mySidenav" class="sidenav bg-white" @mouseenter="hoverIs()" @mouseleave="hoverOut()">
      <div v-if="!mobileView">
        <div
          class="d_icon_box items-center"
          v-for="item in routesList"
          :key="item.path"
          @click="routeTo(item.name)"
        >
          <div class="d_icon_child">
            <div class="p-1 rounded">
              <component :is="item.meta.routeIcon" v-bind="item.meta.routIconSize"></component>
            </div>
          </div>
          <span class="d_items_text">{{ item.meta.title }}</span>
        </div>
      </div>
      <!-- <div class="text-center">
        <span @click="LogOut()"><i class="cursor-pointer z-50 fas fa-right-from-bracket heading text-2xl text-primary"></i></span>
      </div> -->
    </div>
    <div v-else class="sv_sidenav_box">
      <div id="sv_smySidenav" class="sv_sidenav bg-white">
        <div>
          <div
            class="d_icon_box  items-center"
            v-for="item in routesList"
            :key="item.path"
            @click="routeTo(item.name)"
          >
            <div class="d_icon_child">
              <div class="p-1 rounded">
              <component :is="item.meta.routeIcon" v-bind="item.meta.routIconSize"></component>
            </div>
            </div>
            <span class="d_items_text">{{ item.meta.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {removeCookies} from '@/utils/cookies'
import { getCookies } from "@/utils/cookies";
import userSvg from '@/assets/images/svg/userSvg.vue'
import SettingsSvg from '@/assets/images/svg/settingsSvg.vue'
import dashboardSvg from '@/assets/images/svg/dashboardSvg.vue'
import listingSvg from '@/assets/images/svg/listingSvg.vue'
import ProjectsSvg from '@/assets/images/svg/projectsSvg.vue'
import idbadgeIconSvg from '@/assets/images/svg/idbadgeIconSvg.vue'
import WorkpackageSvg from '@/assets/images/svg/workpackageSvg.vue'
import blogSvg from '@/assets/images/svg/blogSvg.vue'
import InfoCircleSvg from '@/assets/images/svg/infoCircleSvg.vue'
import pageSvg from '@/assets/images/svg/pageSvg.vue'
import cogs from "@/assets/images/svg/cogs.vue";
export default {
  components: {
    ProjectsSvg,
    blogSvg,
    WorkpackageSvg,
    InfoCircleSvg,
    pageSvg,
    SettingsSvg,
    cogs,
    userSvg,
    dashboardSvg,
    listingSvg,
    idbadgeIconSvg,
  },
  name: "leftNavbar",
  props: ["routesList"],
  data() {
    return {
      userDetail: {},
      mobileView: false,
      toutInfo: null,
      drawerOpen: false,
      extraSmall: false,
      drawerIsOpen: false,
      showMenuContinue: true
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.$root.$on('openDialog', () => {
      console.log('click click')
      this.hoverIs()
    })
    // console.log('On Mounted Route List', this.routesList)
    this.resizeWindowHandler();
    if (getCookies("lockDrawer") === "draweLocked" && !this.mobileView) {
      this.drawerIsOpen = true;
    } else {
      this.drawerIsOpen = false;
    }
    // console.log("drawerOpen", this.drawerOpen);
    let userData = localStorage.getItem("employeeData1");
    this.userDetail = JSON.parse(userData);
    // console.log("JSON.parse(userData)", JSON.parse(userData));
  },
  methods: {
    hoverIs () {
      if (!this.mobileView) {
        document.getElementById("mySidenav").style.minWidth = "250px";
        document.getElementById("mySidenav").style.maxWidth = "250px";
        document.getElementById("mySidenav").style.boxShadow = "2px 0px 23px -6px rgba(128,128,128,0.52)";
      } else {
        document.getElementById("sv_smySidenav").style.minWidth = "250px";
        document.getElementById("sv_smySidenav").style.maxWidth = "250px";
        document.getElementById("sv_smySidenav").style.boxShadow = "2px 0px 23px -6px rgba(128,128,128,0.52)";
      }
    },
    hoverOut () {
      if (!this.mobileView) {
        document.getElementById("mySidenav").style.minWidth = "80px";
        document.getElementById("mySidenav").style.maxWidth = "80px";
        document.getElementById("mySidenav").style.boxShadow = null;
      } else {
        document.getElementById("sv_smySidenav").style.minWidth = "0px";
        document.getElementById("sv_smySidenav").style.maxWidth = "0px";
        document.getElementById("sv_smySidenav").style.boxShadow = null;
      }
    },
    LogOut () {
      removeCookies('authToken')
      localStorage.clear()
      this.$store.dispatch('GetEmployeePermission', true)
      this.$router.push({name: 'login'})
      location.reload()
    },
    routeTo(path) {
      console.log('path', path)
      console.log('check11', this.$route)
      console.log('$routesList', this.routesList)
      this.$router.push({ name: path })
      // if (this.$route.name === 'addUpdateCustomer') {
      //   alert("Are you sure close form!");
      // } else {
      //    for (let index = 0; index < this.routesList.length; index++) {
      //     if (this.$route.meta.loadPage === this.routesList[index].meta.loadPage) {
      //       this.routesList[index].meta.selected = true;
      //       this.routesList[index].meta.routIconSize.color = colors.white;
      //     } else {
      //       this.routesList[index].meta.selected = false;
      //       this.routesList[index].meta.routIconSize.color = colors.gray2;
      //     }
      //   }
      //   this.$router.push({ name: path }).catch(()=>{});
      //   }

    },
    closeDrawer() {
      this.showMenuContinue = false
        // console.log('check 11', getCookies("lockDrawer"))
      document.getElementById("overlay").style.display = "none";
      if (getCookies("lockDrawer") === "draweUnLocked" || getCookies("lockDrawer") === null) {
        document.getElementById("myTopnav").style.left = "80px";
        document.getElementById("mySidenav").style.minWidth = "80px";
        this.drawerIsOpen = false;
      }
      setTimeout(() => {
        this.showMenuContinue = true
      }, 600);
    },
    openDrawer() {
      // console.log('check 10', this.showMenuContinue)
      setTimeout(() => {
        if (this.showMenuContinue) {
          document.getElementById("mySidenav").style.minWidth = "260px";
          document.getElementById("menu_items_box").style.left = "275px";
          if (getCookies("lockDrawer") === "draweUnLocked") {
            // document.getElementById('myTopnav').style.left = '248px'
            this.showDashboadMenus = false;
            this.showHistory = false;
            this.showAddFavorite = false;
            this.showAvtarMenu = false;
            this.$root.$emit('closeCardPopUp', true)
          }
          this.drawerIsOpen = true;
        }
      }, 400);
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 600) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
    },
  },
};
</script>
<style scoped>
</style>
