import Vue from 'vue'
import Router from 'vue-router'
import { HRM } from '@/View/CMS/router/index.js'
import {projectDetail} from '@/View/CMS/project/pages/router/index.js'

Vue.use(Router)

export const constantRouterMap = [{
        path: '/',
        hidden: true,
        redirect: '/login'
    },
    { // Login Page
        path: '/login',
        component: () =>
            import ('@/View/login/login.vue'),
        hidden: true,
        name: 'login',
        meta: {
            title: 'login',
            selected: false,
            permissionList: []
        }
    },
    { // Dashboard object start
        path: '/dashboard',
        redirect: '/Projects',
        component: () =>
            import ('@/View/CMS/Layout/layout.vue'),
        hidden: true,
        name: 'dashboard',
        meta: {
            title: 'dashboard',
            selected: false,
            permissionList: []
        }
    },
    HRM,
    projectDetail,
]
export default new Router({
    routes: constantRouterMap
})