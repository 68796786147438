// import Cookies from 'js-cookie'
import * as config from '../config.js'
import Cookies from 'vue-cookies'

export function getCookies (Name) {
  if (config.IS_DESKTOP_APP) {
    return localStorage.getItem(Name)
  }
  return Cookies.get(Name)
}
export function setCookies (Name, value) {
  if (config.IS_DESKTOP_APP) {
    return localStorage.setItem(Name, value)
  }
  return Cookies.set(Name, value, { expires: 1 })
}

export function removeCookies (Name) {
  if (config.IS_DESKTOP_APP) {
    return localStorage.removeItem(Name)
  }
  return Cookies.remove(Name)
}

export function setCookiesWithExpire (Name, value, day) {
  if (config.IS_DESKTOP_APP) {
    return localStorage.setItem(Name, value)
  }
  return Cookies.set(Name, value, day + 'd')
}

export function setCookiesWithDomainExpire (Name, value, day, domain) {
  if (config.IS_DESKTOP_APP) {
    return localStorage.setItem(Name, value)
  }
  document.cookie = Name + '=' + value + ';expires=' + day + ';domain=' + domain
  // return Cookies.set(Name, value, { expires: day, domain: domain })
}
