<template>
  <svg :width="width" :height="height" fill="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 8C5.10457 8 6 7.10457 6 6C6 4.89543 5.10457 4 4 4C2.89543 4 2 4.89543 2 6C2 7.10457 2.89543 8 4 8Z" fill="black"/>
  <path d="M4 14C5.10457 14 6 13.1046 6 12C6 10.8954 5.10457 10 4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14Z" fill="black"/>
  <path d="M6 18C6 19.1046 5.10457 20 4 20C2.89543 20 2 19.1046 2 18C2 16.8954 2.89543 16 4 16C5.10457 16 6 16.8954 6 18Z" fill="black"/>
  <path d="M21 7.5C21.5523 7.5 22 7.05228 22 6.5V5.5C22 4.94772 21.5523 4.5 21 4.5H9C8.44772 4.5 8 4.94772 8 5.5V6.5C8 7.05228 8.44772 7.5 9 7.5H21Z" fill="black"/>
  <path d="M22 12.5C22 13.0523 21.5523 13.5 21 13.5H9C8.44772 13.5 8 13.0523 8 12.5V11.5C8 10.9477 8.44772 10.5 9 10.5H21C21.5523 10.5 22 10.9477 22 11.5V12.5Z" fill="black"/>
  <path d="M21 19.5C21.5523 19.5 22 19.0523 22 18.5V17.5C22 16.9477 21.5523 16.5 21 16.5H9C8.44772 16.5 8 16.9477 8 17.5V18.5C8 19.0523 8.44772 19.5 9 19.5H21Z" fill="black"/>
  </svg>
  
  </template>
  <script>
  export default {
    name: 'sheduleSvg', // vue component name
    props: {
      color: {
        type: String,
        default: 'white'
      },
      width: {
        type: String,
        default: '40px'
      },
      height: {
        type: String,
        default: '40px'
      }
    },
    data () {
      return {
      }
    }
  }
  </script>
  