<template>
<svg :width="width" :height="height" fill="black" version="1.1" id="svg2" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" sodipodi:docname="info-sign.svg" inkscape:version="0.48.4 r9939" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1200" enable-background="new 0 0 1200 1200" xml:space="preserve">
<sodipodi:namedview  inkscape:cy="698.84466" inkscape:cx="726.59921" inkscape:zoom="0.37249375" showgrid="false" id="namedview30" guidetolerance="10" gridtolerance="10" objecttolerance="10" borderopacity="1" bordercolor="#666666" pagecolor="#ffffff" inkscape:current-layer="svg2" inkscape:window-maximized="1" inkscape:window-y="24" inkscape:window-height="876" inkscape:window-width="1535" inkscape:pageshadow="2" inkscape:pageopacity="0" inkscape:window-x="65">
	</sodipodi:namedview>
<path id="path12622" inkscape:connector-curvature="0" d="M600,0C268.629,0,0,268.629,0,600s268.629,600,600,600
	s600-268.629,600-600S931.371,0,600,0z M581.177,205.884c27.494,0.001,49.881,7.415,67.163,22.339
	c17.281,14.926,25.928,34.609,25.928,58.96c0,24.353-8.646,43.962-25.928,58.887c-17.282,14.926-39.669,22.413-67.163,22.412
	c-27.494,0.001-49.882-7.486-67.163-22.412c-16.496-14.925-24.756-34.534-24.756-58.887c0-24.351,8.26-44.034,24.756-58.96
	C531.295,213.298,553.683,205.885,581.177,205.884z M667.163,468.604l23.584,27.1c-12.569,32.993-18.896,69.509-18.896,109.57
	v288.721c0,10.212,0.846,17.239,2.417,21.167c2.356,3.928,7.452,7.864,15.308,11.792c14.14,8.641,34.908,16.9,62.402,24.756
	l-8.203,42.407c-67.557-7.07-115.109-10.547-142.603-10.547c-27.494,0-74.587,3.477-141.357,10.547l-9.448-42.407
	c28.279-6.284,49.121-14.157,62.476-23.584c11.783-7.069,17.651-18.42,17.651-34.131v-294.58c0-27.493-10.65-41.235-31.86-41.235
	l-41.235,8.203l-9.375-47.095c28.279-11.782,65.567-23.206,111.914-34.204C606.283,474.086,642.025,468.604,667.163,468.604z"/>
</svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
