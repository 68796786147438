<template>
  <svg :width="width" :height="height" fill="black" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 490 490" xml:space="preserve">
	<g>
	<g id="XMLID_50_">
		<g>
			<polygon style="fill:#FFFFFF;" points="480,110 480,480 110,480 110,425 280,425 280,147.5 202.5,70 110,70 110,10 380,10 
				380,110 			"/>
			<polygon style="fill:#AFB6BB;" points="480,110 380,110 380,10 			"/>
			<polygon style="fill:#E7ECED;" points="280,147.5 280,425 110,425 10,425 10,70 110,70 202.5,70 202.5,147.5 			"/>
			<polygon style="fill:#AFB6BB;" points="280,147.5 202.5,147.5 202.5,70 			"/>
		</g>
		<g>
			<path style="fill:#000000;" d="M489.976,110c-0.001-2.602-0.993-5.158-2.905-7.071l-100-100c-1.913-1.912-4.47-2.903-7.071-2.904
				V0H110c-5.523,0-10,4.477-10,10v50H10C4.477,60,0,64.478,0,70v355c0,5.522,4.477,10,10,10h90v45c0,5.522,4.477,10,10,10h370
				c5.523,0,10-4.478,10-10V110H489.976z M390,34.143L455.858,100H390V34.143z M20,80h172.5v67.5c0,5.523,4.477,10,10,10H270V415H20
				V80z M212.5,94.143l43.357,43.357H212.5V94.143z M120,470v-35h160c5.523,0,10-4.478,10-10V147.5c0-2.652-1.054-5.195-2.929-7.071
				l-77.5-77.5C207.696,61.054,205.152,60,202.5,60H120V20h250v90c0,5.522,4.477,10,10,10h90v350H120z"/>
			<rect x="310" y="160" style="fill:#000000;" width="130" height="20"/>
			<rect x="310" y="220" style="fill:#000000;" width="130" height="20"/>
			<rect x="310" y="280" style="fill:#000000;" width="130" height="20"/>
			<rect x="310" y="340" style="fill:#000000;" width="130" height="20"/>
			<rect x="310" y="400" style="fill:#000000;" width="130" height="20"/>
			<rect x="45" y="105" style="fill:#000000;" width="65" height="20"/>
			<rect x="45" y="145" style="fill:#000000;" width="65" height="20"/>
			<rect x="45" y="205" style="fill:#000000;" width="200" height="20"/>
			<rect x="45" y="255" style="fill:#000000;" width="200" height="20"/>
			<rect x="45" y="305" style="fill:#000000;" width="200" height="20"/>
			<rect x="45" y="355" style="fill:#000000;" width="200" height="20"/>
		</g>
	</g>
	</g>
</svg>
  </template>
  <script>
  export default {
    name: 'sheduleSvg', // vue component name
    props: {
      color: {
        type: String,
        default: 'white'
      },
      width: {
        type: String,
        default: '40px'
      },
      height: {
        type: String,
        default: '40px'
      }
    },
    data () {
      return {
      }
    }
  }
  </script>
  
<!-- <template>

  <svg :width="width" :height="height" fill="black" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 548.291 548.291"
  xml:space="preserve">
<g>
 <path d="M189.2,293.611c-1.806-6.027-3.609-13.564-5.119-19.583h-0.296c-1.515,6.019-3.027,13.703-4.665,19.583l-6.037,21.554
   h22.452L189.2,293.611z"/>
 <path d="M486.201,196.124h-13.166V132.59c0-0.396-0.062-0.795-0.115-1.196c-0.021-2.523-0.825-5-2.552-6.963L364.657,3.677
   c-0.033-0.031-0.064-0.042-0.085-0.073c-0.63-0.707-1.364-1.292-2.143-1.795c-0.229-0.157-0.461-0.286-0.702-0.421
   c-0.672-0.366-1.387-0.671-2.121-0.892c-0.2-0.055-0.379-0.136-0.577-0.188C358.23,0.118,357.401,0,356.562,0H96.757
   C84.894,0,75.256,9.651,75.256,21.502v174.613H62.092c-16.971,0-30.732,13.756-30.732,30.733v159.812
   c0,16.968,13.761,30.731,30.732,30.731h13.164V526.79c0,11.854,9.638,21.501,21.501,21.501h354.776
   c11.853,0,21.501-9.647,21.501-21.501V417.392h13.166c16.966,0,30.729-13.764,30.729-30.731V226.854
   C516.93,209.872,503.167,196.124,486.201,196.124z M96.757,21.502h249.054v110.009c0,5.939,4.817,10.75,10.751,10.75h94.972v53.861
   H96.757V21.502z M404.394,296.781v18.678h-37.361v24.104h41.733v18.846h-64.778v-101.55h62.674v18.835h-39.629v21.098h37.361
   V296.781z M292.592,340.918c5.265,0,9.484-0.6,11.297-1.501v-21.25h-15.665v-17.933h37.816v53.502
   c-7.081,2.404-20.492,5.724-33.9,5.724c-18.538,0-31.953-4.674-41.287-13.712c-9.347-8.736-14.475-21.996-14.321-36.926
   c0.153-33.743,24.712-53.026,58.007-53.026c13.107,0,23.196,2.562,28.174,4.976l-4.819,18.381
   c-5.575-2.415-12.494-4.373-23.648-4.373c-19.132,0-33.591,10.86-33.591,32.846C260.649,328.562,273.759,340.918,292.592,340.918z
    M231.399,358.409H206.69l-7.848-26.068h-29.066l-7.239,26.068h-23.809l31.042-101.555h30.124L231.399,358.409z M90.356,322.083
   v36.325H67.607v-100.2c7.069-1.211,17.024-2.114,31.032-2.114c14.164,0,24.266,2.709,31.042,8.139
   c6.478,5.121,10.833,13.566,10.833,23.497c0,9.954-3.308,18.394-9.326,24.115c-7.833,7.382-19.439,10.684-33,10.684
   C95.172,322.535,92.458,322.398,90.356,322.083z M451.534,520.962H96.757v-103.57h354.776V520.962z M449.314,359.91
   c-11.598,0-23.04-3.013-28.773-6.173l4.661-18.991c6.18,3.165,15.664,6.336,25.46,6.336c10.552,0,16.121-4.373,16.121-10.992
   c0-6.337-4.814-9.959-17.014-14.322c-16.876-5.884-27.884-15.212-27.884-29.983c0-17.336,14.467-30.581,38.416-30.581
   c11.463,0,19.89,2.415,25.915,5.124l-5.119,18.528c-4.072-1.959-11.296-4.816-21.248-4.816c-9.943,0-14.763,4.527-14.763,9.797
   c0,6.479,5.729,9.328,18.835,14.312c17.942,6.619,26.363,15.969,26.363,30.288C490.295,345.443,477.192,359.91,449.314,359.91z"/>
 <path d="M117.765,288.341c0-9.341-6.478-14.915-17.911-14.915c-4.682,0-7.848,0.451-9.503,0.903v29.985
   c1.95,0.451,4.37,0.592,7.677,0.592C110.246,304.907,117.765,298.74,117.765,288.341z"/>
</g>
</svg>
  </template>
  <script>
  export default {
    name: 'sheduleSvg', // vue component name
    props: {
      color: {
        type: String,
        default: 'white'
      },
      width: {
        type: String,
        default: '40px'
      },
      height: {
        type: String,
        default: '40px'
      }
    },
    data () {
      return {
      }
    }
  }
  </script>
   -->