<template>
<svg :width="width" :height="height" :fill="color" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Path</title>
    <g id="Symbols" stroke="none" stroke-width="1"  fill-rule="evenodd">
        <g id="component/left-menu-collapsed" transform="translate(-20.000000, -174.000000)"  fill-rule="nonzero">
            <g id="menu/active/user" transform="translate(15.000000, 169.000000)">
                <path d="M17.7374302,22.8669184 L17.7374302,29.1434639 C16.1934992,29.8607834 11.2772981,32.2770175 6.71660741,35.7597924 C5.61960386,36.5903728 5,37.8268051 5,39.1481831 L5,44.1411043 C5,44.613025 5.41645505,45 5.92432707,45 C6.43219909,45 6.84865414,44.613025 6.84865414,44.1411043 L6.84865414,39.1481831 C6.84865414,38.3459179 7.23463687,37.5908447 7.89487049,37.0811704 C12.6891823,33.4190656 17.8897918,30.9839547 18.8750635,30.5309108 C19.3118334,30.3327041 19.5860843,29.9174139 19.5860843,29.4738084 L19.5860843,22.4421897 C19.5860843,22.1590373 19.4337227,21.8853233 19.1797867,21.7343086 C18.448451,21.2812647 18.0116811,20.5261916 18.0116811,19.714488 L18.0116811,12.4185937 C18.0116811,9.26616328 20.7643474,6.708353 24.1569325,6.708353 L25.8430675,6.708353 C29.2356526,6.708353 31.9883189,9.26616328 31.9883189,12.4185937 L31.9883189,19.7050496 C31.9883189,20.5167532 31.551549,21.2718263 30.8202133,21.7248702 C30.5662773,21.8853233 30.4139157,22.1495989 30.4139157,22.4327513 L30.4139157,29.46437 C30.4139157,29.9079755 30.698324,30.3327041 31.1249365,30.5214724 C32.1203657,30.9745163 37.3108177,33.4096272 42.1051295,37.0717319 C42.7653631,37.5814063 43.1513459,38.3364795 43.1513459,39.1387447 L43.1513459,44.1411043 C43.1513459,44.613025 43.5678009,45 44.0756729,45 C44.5835449,45 45,44.613025 45,44.1411043 L45,39.1481831 C45,37.8268051 44.3702387,36.5903728 43.2833926,35.7597924 C38.7227019,32.2770175 33.8065008,29.8702218 32.2625698,29.1434639 L32.2625698,22.8669184 C33.257999,22.08353 33.8369731,20.9320434 33.8369731,19.7050496 L33.8369731,12.4185937 C33.8369731,8.32232185 30.2513966,5 25.853225,5 L24.1670899,5 C19.7587608,5 16.1833418,8.33176026 16.1833418,12.4185937 L16.1833418,19.7050496 C16.1630269,20.9320434 16.742001,22.08353 17.7374302,22.8669184 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'red'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
