import colors from '@/assets/colors.json'
import layout from '@/View/CMS/project/pages/Layout/layout.vue'
export const projectDetail = { // dashboard object start
    path: 'project/Detail',
    component: layout,
    hidden: false,
    redirect: '/project/pages/:projectId',
    name: 'ProjectDetail',
    meta: {
        breadCrumb: 'ProjectDetail',
        title: 'ProjectDetail',
        icon: '',
        selected: false,
        permission: '',
        routeIcon: 'listingSvg',
        loadPage: 'workPakcages',
        routIconSize: { height: '40px', width: '40px', color:  colors.gray2 }
    },
    children: [
      {
        path: '/projects',
        component: () => import('@/View/CMS/project/projectList.vue'),
        name: 'Projects',
        meta: {
            breadCrumb: 'Projects',
            icon: 'fa fa-project-diagram',
            title: 'Projects',
            routeIcon: 'listingSvg',
            permissionList: ['abc'],
            loadPage: 'listingSvg',
            routIconSize: { height: '40px', width: '40px', color:  colors.gray2 }
        }
      },
      {
        path: '/pages/detail/:projectId',
        component: () => import('@/View/CMS/project/pages/component/pages/pageOverView.vue'),
        name: 'PageDetail',
        meta: {
            breadCrumb: 'Pages',
            permissionList: ['IsAdmin'],
            icon: 'fa fa-project-diagram',
            title: 'Page Detail',
            routeIcon: 'InfoCircleSvg',
            loadPage: 'InfoCircleSvg',
            routIconSize: { height: '40px', width: '40px', color:  colors.gray2 }
        }
      },
      {
        path: '/project/pages/:projectId',
        component: () => import('@/View/CMS/project/pages/component/specificPageList.vue'),
        name: 'specificProject',
        meta: {
          breadCrumb: 'specificProject',
          icon: 'fas fa-info-circle',
          title: 'Pages',
          selected: false,
          permissionList: ['abc'],
          routeIcon: 'pageSvg',
          // routeIcon: 'ProjectsSvg',
          routIconSize: { height: '40px', width: '40px', color:  colors.gray2 }
        }
      },
      {
        path: '/project/:projectId/blog',
        component: () => import('@/View/CMS/project/blogs/blogsList.vue'),
        name: 'Blogs',
        meta: {
            breadCrumb: 'Blogs',
            icon: 'fa fa-project-diagram',
            title: 'Blogs',
            permissionList: ['abc'],
            routeIcon: 'blogSvg',
            loadPage: 'listingSvg',
            routIconSize: { height: '40px', width: '40px', color:  colors.gray2 }
        }
      },
      {
        path: '/component/:projectId',
        component: () => import('@/View/CMS/project/pages/component/components/componentList.vue'),
        name: 'Components',
        meta: {
            breadCrumb: 'Components',
            icon: 'fa fa-project-diagram',
            title: 'Components',
            permissionList: ['IsAdmin'],
            routeIcon: 'WorkpackageSvg',
            loadPage: 'listingSvg',
            routIconSize: { height: '40px', width: '40px', color:  colors.gray2 }
        }
      },
      {
        path: '/component/:projectId/detail/:componentId',
        component: () => import ('@/View/CMS/project/pages/component/components/componentDetail.vue'),
        name: 'ComponentDetail',
        hidden: true,
        meta: {
            breadCrumb: 'ComponentDetail',
            icon: 'fa fa-project-diagram',
            title: 'ComponentDetail',
            routeIcon: 'WorkpackageSvg',
            loadPage: 'listingSvg',
        }
      },
      {
        path: '/page/:projectId/detail/:pageMasterId',
        component: () => import ('@/View/CMS/project/pages/component/pages/component/pagespecificDetail.vue'),
        name: 'specificPageDetailInPage',
        hidden: true,
        meta: {
            breadCrumb: 'specificPageDetailInPage',
            icon: 'fa fa-project-diagram',
            title: 'Page Detail',
            routeIcon: 'WorkpackageSvg',
            loadPage: 'listingSvg',
        }
      },
      {
        path: '/project/:projectId/blog/:blogId',
        component: () => import('@/View/CMS/project/blogs/addEditblog.vue'),
        name: 'EditBlogs',
        hidden: true,
        meta: {
            breadCrumb: 'EditBlogs',
            icon: 'fa fa-project-diagram',
            title: 'EditBlogs',
            routeIcon: 'blogSvg',
            loadPage: 'listingSvg',
        }
      },
      {
        path: '/page/:projectId/add/:pageMasterId',
        component: () => import ('@/View/CMS/project/pages/component/pages/component/addEditPage.vue'),
        name: 'AddNewPage',
        hidden: true,
        meta: {
            breadCrumb: 'AddNewPage',
            icon: 'fa fa-project-diagram',
            title: 'Page Detail',
            routeIcon: 'WorkpackageSvg',
            loadPage: 'listingSvg',
        }
      },
      {
        path: '/page/:projectId/edit/:pageMasterId',
        component: () => import ('@/View/CMS/project/pages/component/pages/component/addEditPage.vue'),
        name: 'EditPage',
        hidden: true,
        meta: {
            breadCrumb: 'EditPage',
            icon: 'fa fa-project-diagram',
            title: 'Page Detail',
            routeIcon: 'WorkpackageSvg',
            loadPage: 'listingSvg',
        }
      },
      // {
      //   path: '/pages',
      //   component: () => import('@/View/CMS/project/pages/component/pages/page.vue'),
      //   name: 'Pages',
      //   meta: {
      //       breadCrumb: 'Pages',
      //       icon: 'fa fa-project-diagram',
      //       title: 'Projects',
      //       routeIcon: 'ProjectsSvg',
      //       loadPage: 'listingSvg',
      //       routIconSize: { height: '40px', width: '40px', color:  colors.gray2 }
      //   }
      // }
    ]
}