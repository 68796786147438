import axios from 'axios'
import * as config from '../config.js'
/**
 * init class API
 * @param opt
 * @returns {MyWorkAppApi}
 * @constructor
 */

function MyWorkAppApi (opt) {
  if (!(this instanceof MyWorkAppApi)) {
    return new MyWorkAppApi(opt)
  }
  opt = opt || {}
  this.classVersion = '1.0.0'
  this.api = axios.create({
    baseURL: config.API_ROOT,
    timeout: 9000000
  })
}

/**
 * Request to the server,
 * You fixed: https://gist.github.com/pranavrajs/66bccee3f8ba100742a1273db6f587af
 * @param url
 * @param callback
 * @returns {axios.Promise}
 * @private
 */

MyWorkAppApi.prototype._requestAuthPost = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Authorization': 'Bearer 23432567654',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  })
}

MyWorkAppApi.prototype._requestDataPost = function (url, contentType, data, callback) {
  var headers = {
    'Authorization': 'Bearer eb7ca14c72364a3c696f0fc95e256a08S1UHQsDk3YEc1TK/iMVaitNoRbTLbvDUqAGEnXA4ezoJtgw=',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}

MyWorkAppApi.prototype._requestDataPostFileAttachment = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Authorization': 'Bearer 23432567654',
    'Content-Type': contentType,
    responseType: 'blob'
  }
  return this.api.post(url, data, headers).then((response) => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}

MyWorkAppApi.prototype._requestDataGet = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Authorization': 'Bearer 23432567654',
    'Content-Type': contentType
  }
  var param = ''
  if (data !== '') {
    param = '/' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
MyWorkAppApi.prototype._requestFileDataGet = function (url, contentType, data, callback) {
  var headers = {
    'Authorization': 'Bearer 23432567654',
    responseType: 'arraybuffer'
  }
  var param = ''
  if (data !== '') {
    param = '/' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
MyWorkAppApi.prototype.login = async function (data, callback) {
  const requestUrl = 'api/v1/auth/loginToSpecificCompany'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}




// add document
// delete from tempt storage

// *******************TIMESHEET API START HERE*****************************/


// TIMESHEET UPDATE


MyWorkAppApi.prototype.GetTrackingAccess = async function (data, callback) {
  const requestUrl = 'api/v1/hrm/employee/getTrackingAccess'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}


MyWorkAppApi.prototype.loginToSpecificCompany = async function (data, callback) {
  const requestUrl = 'api/v1/auth/login'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyWorkAppApi.prototype.getUserList = async function (data, callback) {
  const requestUrl = '/api/v1/user/list'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyWorkAppApi.prototype.LoginUserLeaveDetails = async function (data, callback) {
  const requestUrl = 'api/v1/hrm/leaveManagement/myLeaveDetails'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyWorkAppApi.prototype.DeviceRequestPersistent = async function (data, callback) {
  const requestUrl = 'api/v1/notification/persistent'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyWorkAppApi.prototype.GetDatabaseUsageOFEmployee = async function (data, callback) {
  const requestUrl = 'api/v1/systemSetting/getCompanyStorageUsage'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}



MyWorkAppApi.prototype.getProjectPermission = async function (data, callback) {
  const requestUrl = 'api/v1/project/getProjectPermission'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}










export default MyWorkAppApi
